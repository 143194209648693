import React from 'react';
import { Avatar, Box, Breakpoint, Grid } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PeoplesGroupOne from './PeoplesGroupOne';
import PeoplesGroupTwo from './PeoplesGroupTwo';
import DoubleQuotesIcon from '../../components/Icons/DoubleQuotesIcon';

const HomePageFeedBack = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm
        container
        direction="column"
        sx={{
          mt: 15,
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            textAlign: 'center',
            alignItems: 'center',
          },
          [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
            ml: 15,
          },
        }}
      >
        <Grid
          item
          sx={{
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              ml: 7,
            },
          }}
        >
          <Typography variant="xlDisplay" component="p">
            Any Age.
          </Typography>
          <Typography variant="xlDisplay" component="p">
            Any life story.
          </Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                display: 'none',
              },
            }}
          >
            <PeoplesGroupTwo />
          </Box>
        </Grid>
        <Grid item>
          <Box
            component={DoubleQuotesIcon}
            sx={{
              width: '167px',
              height: '130px',
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                ml: -30,
              },
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            mt: 1,
          }}
        >
          <Typography
            variant="feedback"
            component="p"
            sx={{
              textAlign: 'left',
              maxWidth: '326px',
              mt: -10,
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                maxWidth: '467px',
                ml: 7,
              },
            }}
          >
            {`I have been putting this off for years,
            and I got it taken care of in 25 minutes?
            It really couldn't have been easier.`}
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box>
              <Avatar
                src="images/person1.png"
                sx={{
                  width: 106,
                  height: 106,
                  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                    display: 'none',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="sCaps"
                component="p"
                sx={{
                  maxWidth: '138px',
                  mt: 2,
                  textAlign: 'left',
                  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                    ml: 7,
                  },
                }}
              >
                Chris Fergison, 62 Retired Teacher
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
            mr: -2,
          }}
        >
          <PeoplesGroupOne />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePageFeedBack);
