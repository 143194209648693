import React from 'react';
import { Avatar, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const AttorneyGrid = () => {
  const theme = useTheme();
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        <Avatar
          src="images/attorney-board.png"
          variant="square"
          sx={{ width: 52, height: 43 }}
        />
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={0}>
          <Grid item xs>
            <Typography
              sx={{
                fontSize: 8,
                fontFamily: 'helvetica',
                fontStyle: 'regular',
                fontWeight: 'normal',
                lineHeight: 1.2,
                letterSpacing: '0.17em',
                textTransform: 'uppercase' as const,
                color: theme.palette.grey[800],
              }}
              component="p"
            >
              Approved By The
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: 'Trajan Pro 3',
                fontStyle: 'regular',
                fontWeight: 'normal',
                lineHeight: 1.2,
                letterSpacing: '0.045em',
                textTransform: 'uppercase' as const,
                color: theme.palette.common.attorneyBlue,
              }}
              component="p"
            >
              CERTIFIED ATTORNEY BOARD
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AttorneyGrid);
