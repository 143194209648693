import React from 'react';
import { Box, Breakpoint, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import DoubleQuotesIcon from '../../components/Icons/DoubleQuotesIcon';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';

const FeedbackSection = ({ imgSource, feedback, author }:any) => {
  const theme = useTheme();
  return <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
    <Grid item>
      <Avatar
        alt={author}
        src={imgSource}
        sx={{ width: 248, height: 248 }}
      />
    </Grid>
    <Grid item
      justifyContent="left"
      sx={{
        ml: 4,
        mt: 12,
        [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
          mt: -2,
        },
      }}>
      <Box
        component={DoubleQuotesIcon}
        sx={{
          width: '167px',
          height: '130px',
        }}/>
      <Typography
        variant="feedback"
        component="p"
        sx={{
          maxWidth: '416px',
          marginLeft: 4,
          marginTop: -10,
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            maxWidth: '326px',
          },
        }}
      >
        {feedback}
      </Typography>
      <Typography
        variant="sCaps"
        component="p"
        sx={{
          maxWidth: '138px',
          marginLeft: 4,
          marginTop: 2,
        }}
      >
        {author}
      </Typography>
    </Grid>
  </Grid>;
};

export default React.memo(FeedbackSection);
