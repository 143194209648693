import React from 'react';
import { Avatar, Box } from '@mui/material';

const PeoplesGroupOne = () => {
  return <Box sx={{ mt :2, mb: 1, mr: -6 }}>
    <Avatar
      src="images/photo7.png"
      sx={{
        width: 133,
        height: 133,
        mb: -5,
        ml: -5,
      }}
    />
    <Avatar
      src="images/ladyWithChild.png"
      sx={{
        width: 238,
        height: 238,
        transform: 'scaleX(-1)',
      }}
    />
    <Avatar
      src="images/photo6.png"
      sx={{
        width: 106,
        height: 106,
        mt: -35,
        ml: 20,
      }}
    />
    <Avatar
      src="images/photo5.png"
      sx={{
        width: 106,
        height: 106,
        mt: 14,
        ml: -5,
      }}
    />
    <Avatar
      src="images/ladyWithChild.png"
      sx={{
        width: 86,
        height: 86,
        mt: -10,
        ml: 10,
      }}
    />
    <Avatar
      src="images/coupleOne.png"
      sx={{
        width: 150,
        height: 150,
        mt: -15,
        ml: 22,
      }}
    />
  </Box>;
};

export default React.memo(PeoplesGroupOne);
