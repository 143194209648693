import React from 'react';
import { Box, Breakpoint, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';

const HomePagePlan = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      sx={{
        mt: 10,
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item>
        <Typography variant="xlDisplay" component="p">
          What about no plan?
        </Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            width: 572,
            height: 154,
            backgroundColor: theme.palette.grey[400],
            mt: 4,
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              width: 286,
              height: 264,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePagePlan);
