import React from 'react';
import { Box, Breakpoint, Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { BREAKPOINTS } from '../../variables/constant';
import HomePageIntroduction from './HomePageIntroduction';
import HomePageFeedBack from './HomePageFeedback';
import HomePageComparison from './HomePageComparison';
import HomePagePlan from './HomePagePlan';
import FeedbackSection from './FeedbackSection';
import { apiUrls } from '../../variables/urls';
import Logo from '../../components/Icons/Logo';

const Home = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          sx={{
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
            mt: 3,
          }}
        >
          <Link
            to={apiUrls.HOME}
            component={RouterLink}
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              ml: 2,
            }}
          >
            <Box
              component={Logo}
              sx={{
                width: '100px',
                height: '50px',
              }}
            />
          </Link>
        </Grid>
        <Grid
          item
          sx={{
            mr: 3,
            mt: 3,
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              textAlign: 'right',
              width: '100%',
            },
          }}
        >
          <Link to="/login" component={RouterLink}>
            Log In
          </Link>
        </Grid>
      </Grid>
      <HomePageIntroduction />
      <HomePageFeedBack />
      <HomePageComparison />
      <HomePagePlan />
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          mt: 6,
          mb: 6,
        }}
      >
        <FeedbackSection
          imgSource="images/person2.png"
          feedback={`I showed this website to my uncle who is a
           lawyer and he said “wow, that’s the
           future of Estate Planning`}
          author="Mindy Foster, 35 interior designer"
        />
        <FeedbackSection
          imgSource="images/person3.png"
          feedback={`It’s so nice to have peace of mind knowing
            that my kids will be taken care of if
            something happened to me.`}
          author="Greg Keefe, 54 FARMER"
        />
        <FeedbackSection
          imgSource="images/person4.png"
          feedback="I own two motorcycles now. I need this."
          author="jason norton, 33 genius"
        />
      </Grid>
    </Box>
  );
};

export default React.memo(Home);
