import { Breakpoint, Button, Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { BREAKPOINTS } from '../../variables/constant';
import ComparisonTable from './ComparisonTable';
import { messages } from './constants';
import React from 'react';

const HomePageComparison = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm
      container
      direction="column"
      sx={{
        mt: 10,
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item>
        <Typography
          variant="xlDisplay"
          component="p"
          sx={{
            maxWidth: '547px',
            textAlign: 'center',
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              maxWidth: 345,
            },
          }}
        >
          Can I really do something like this online?
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <ComparisonTable />
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            to="/basic-info"
            component={RouterLink}
            sx={{
              width: '252px',
              mt: 1,
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                display: 'none',
              },
            }}
          >
            {messages.getStarted}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePageComparison);
