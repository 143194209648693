import { useContext, useEffect, useState } from 'react';
import {
  OnBoardingStatus,
  useDocumentPdfLazyQuery,
  usePaymentDetailsQuery,
  useUpdateOnBoardingStatusMutation,
} from '../../generated/graphql';
import { apiUrls } from '../../variables/urls';
import { useHistory, useLocation } from 'react-router-dom';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { useAuthProvider } from '../../core/authContext';
import useStripeCheckout from '../Checkout/stripe-payment-hooks';

const useDashboard = () => {
  const history = useHistory();
  const { getUser } = useAuthProvider();
  const { loading, redirectToStripeCheckout } = useStripeCheckout();
  const user = getUser();
  let companyID = user?.user_info?.company_id;
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);
  const userData = planDocuments?.user_info;

  const [
    planDocumentPDF,
    { loading: loadingPDFURL, data: documentPDF, error: documentPDFError },
  ] = useDocumentPdfLazyQuery({
    fetchPolicy: 'network-only',
  });

  const search = useLocation().search;

  useEffect(() => {
    if (!!companyID) {
      return;
    }
    const paymentCanceled = new URLSearchParams(search).get('canceled');
    if (
      paymentCanceled &&
      userData?.on_boarding_status === OnBoardingStatus.Payment
    ) {
      setShowPaymentModal(true);
    } else if (
      userData?.on_boarding_status === OnBoardingStatus.Payment &&
      !paymentCanceled
    ) {
      redirectToStripeCheckout();
    }
  }, [userData, search, companyID]);

  useEffect(() => {
    if (documentPDFError) {
      openSnackbar(
        { message: getErrorMessage(documentPDFError) },
        NotifierType.Error,
      );
    }

    if (!loadingPDFURL) {
      if (documentPDF?.documentPDF?.family_trust) {
        window.open(
          documentPDF?.documentPDF?.family_trust,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.self_will) {
        window.open(
          documentPDF?.documentPDF?.self_will,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.power_of_attorney) {
        window.open(
          documentPDF?.documentPDF?.power_of_attorney,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.medical_power_of_attorney) {
        window.open(
          documentPDF?.documentPDF?.medical_power_of_attorney,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.medical_directive) {
        window.open(
          documentPDF?.documentPDF?.medical_directive,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.spouse_will) {
        window.open(
          documentPDF?.documentPDF?.spouse_will,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.spouse_power_of_attorney) {
        window.open(
          documentPDF?.documentPDF?.spouse_power_of_attorney,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.spouse_medical_power_of_attorney) {
        window.open(
          documentPDF?.documentPDF?.spouse_medical_power_of_attorney,
          '_blank',
          'noopener,noreferrer',
        );
      }
      if (documentPDF?.documentPDF?.spouse_medical_directive) {
        window.open(
          documentPDF?.documentPDF?.spouse_medical_directive,
          '_blank',
          'noopener,noreferrer',
        );
      }
    }
  }, [loadingPDFURL, documentPDF, documentPDFError]);

  const { data: paymentDetails, loading: loadingPaymentDetails } =
    usePaymentDetailsQuery({
      fetchPolicy: 'network-only',
      skip: !user || !!companyID,
    });

  const [updateOnBoardingStatus, { loading: loadingOnBoardingStatus }] =
    useUpdateOnBoardingStatusMutation();

  const getCurrentState = () => {
    let status = 0;
    let text = '';
    let btnText = 'Continue with payment';
    switch (userData?.on_boarding_status) {
      case OnBoardingStatus.Payment: {
        status = 0;
        text =
          'Your personalized estate documents are ready to ship! Just finish up your one-time payment and you’ll be good to go.';
        btnText = 'Continue with payment';
        break;
      }
      case OnBoardingStatus.BasicInfo:
      case OnBoardingStatus.Plan:
      case OnBoardingStatus.PersonalInformation:
      case OnBoardingStatus.Children:
      case OnBoardingStatus.Guardianship:
      case OnBoardingStatus.Pets:
      case OnBoardingStatus.Trustees:
      case OnBoardingStatus.Assets:
      case OnBoardingStatus.Beneficiaries:
      case OnBoardingStatus.PowerOfAttorney: {
        status = 1;
        text =
          'Just a few more questions before we can complete your {will or trust}. Click below to pick up where you left off.';
        btnText = 'Continue with documents';
        break;
      }
      case OnBoardingStatus.Notarization: {
        status = 2;
        text =
          'Congrats, your documents are on their way! When they arrive, click here and we’ll help you get them notarized and activated.';
        btnText = 'I received my documents';
        break;
      }
      case OnBoardingStatus.DocumentReceived: {
        status = 3;
        text = '';
        btnText = '';
        break;
      }
      default: // Do nothing
    }
    return { status, text, btnText };
  };

  const onRedirectToButtonClick = (status: number) => async () => {
    if (status === 2) {
      try {
        await updateOnBoardingStatus({
          variables: {
            input: {
              on_boarding_status: OnBoardingStatus.DocumentReceived,
            },
          },
        });
        history.push(apiUrls.NEAR_BY_NOTARY);
      } catch (e: any) {
        openSnackbar({ message: e?.message }, NotifierType.Error);
      }
    } else if (status === 1) {
      switch (userData?.on_boarding_status) {
        case OnBoardingStatus.BasicInfo:
          history.push(apiUrls.BASIC_INFO);
          break;
        case OnBoardingStatus.Plan:
          history.push(apiUrls.PLAN);
          break;
        case OnBoardingStatus.PersonalInformation:
          history.push(apiUrls.PERSONAL_INFORMATION);
          break;
        case OnBoardingStatus.Children:
          history.push(apiUrls.CHILDREN);
          break;
        case OnBoardingStatus.Guardianship:
          history.push(apiUrls.GUARDIANSHIP);
          break;
        case OnBoardingStatus.Pets:
          history.push(apiUrls.PETS);
          break;
        case OnBoardingStatus.Trustees:
          history.push(apiUrls.TRUSTEES);
          break;
        case OnBoardingStatus.Assets:
          history.push(apiUrls.ASSETS);
          break;
        case OnBoardingStatus.Beneficiaries:
          history.push(apiUrls.BENEFICIARIES);
          break;
        case OnBoardingStatus.PowerOfAttorney:
          history.push(apiUrls.POWER_OF_ATTORNEY_GENERAL);
          break;
        default:
          history.push(apiUrls.PERSONAL_INFORMATION);
      }
    } else if (status === 0) {
      redirectToStripeCheckout();
    }
  };

  const onViewPDF = async () => {
    try {
      await planDocumentPDF();
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const proceedToPayment = () => {
    redirectToStripeCheckout();
    setShowPaymentModal(false);
  };

  return {
    user,
    state: getCurrentState(),
    planDocuments,
    loading:
      loadingPlanDocuments ||
      loadingPaymentDetails ||
      loadingOnBoardingStatus ||
      loadingPDFURL ||
      loading,
    paymentDetails,
    onViewPDF,
    onRedirectToButtonClick,
    proceedToPayment,
    showPaymentModal,
    companyID,
  };
};

export default useDashboard;
