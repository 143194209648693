export const messages = {
  planningTitle: 'Easy Estate Planning for Everyone',
  planningSubTitle: 'A better way to create a safe,  secure plan for handling all your assets when you’re gone.',
  getStarted: 'Get Started',
  howItWorks: 'How it works',
  stepOneTitle: 'Step 1',
  stepOneMessage: 'Choose your plan',
  stepOneInfo: '(don’t worry, we’ll ask a few simple questions to help you decide)',
  stepTwoTitle: 'Step 2',
  stepTwoMessage: 'Fill out your information',
  stepThreeTitle: 'Step 3',
  stepThreeMessage: 'Receive a personalized estate plan',
  stepThreeInfo: '(100% ready for you to sign and notarize so it’s legally binding)',
};
