import React from 'react';
import { Avatar, Box } from '@mui/material';

const PeoplesGroupOne = () => {
  return <Box>
    <Avatar
      src="images/person1.png"
      sx={{
        width: 561,
        height: 561,
      }}
    />
    <Avatar
      src="images/photo6.png"
      sx={{
        width: 146,
        height: 146,
        mt: -40,
        ml: 50,
      }}
    />
    <Avatar
      src="images/photo5.png"
      sx={{
        width: 194,
        height: 194,
        mt: -10,
        ml: -14,
      }}
    />
    <Avatar
      src="images/ladyWithChild.png"
      sx={{
        width: 248,
        height: 246,
        mt: -5,
        ml: 30,
      }}
    />
  </Box>;
};

export default React.memo(PeoplesGroupOne);
