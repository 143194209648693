/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const DoubleQuotesIcon = (props: any) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="167"
      height="130"
      viewBox="0 0 167 130"
      fill="none"
      {...props}
    >
      <path
        d="M87.1761 99.0726C87.1761 80.5511 94.0032 62.379 107.657 44.5564C121.661 26.3844 140.567 11.5323 164.374
           0L167 7.33872C150.545 17.4731 137.416 28.4812 127.613 40.3629C117.81 52.2446 112.384 64.1263 111.333
           76.008C121.486 67.2715 130.589 62.9032 138.642 62.9032C143.193 62.9032 146.694 64.6506 149.145
           68.1452C151.945 71.2903 153.346 75.6586 153.346 81.25C153.346 88.5887 151.245 96.1021 147.044 103.79C142.843
           111.479 137.416 117.769 130.764 122.661C124.112 127.554 117.285 130 110.283 130C94.8784 130 87.1761 119.691
           87.1761 99.0726ZM0 99.0726C0 80.5511 6.82704 62.379 20.4811 44.5564C34.4853 26.3844 53.391 11.5323 77.1981
           0L79.8239 7.33872C63.369 17.4731 50.24 28.4812 40.4371 40.3629C30.6342 52.2446 25.2076 64.1263 24.1572
           76.008C34.3103 67.2715 43.413 62.9032 51.4654 62.9032C56.0168 62.9032 59.5178 64.6506 61.9686
           68.1452C64.7694 71.2903 66.1698 75.6586 66.1698 81.25C66.1698 88.5887 64.0692 96.1021 59.8679
           103.79C55.6667 111.479 50.24 117.769 43.5881 122.661C36.9361 127.554 30.109 130 23.1069 130C7.70231 130 0
           119.691 0 99.0726Z"
        fill={theme.palette.common.calypso}
        fillOpacity="0.25"
      />
    </SvgIcon>
  );
};

export default DoubleQuotesIcon;
