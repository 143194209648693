import React from 'react';
import {
  Box,
  Breakpoint,
  Button,
  Grid,
  Link as MUILink,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { DescriptionOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BREAKPOINTS } from '../../variables/constant';
import StepperContainer from './Stepper';
import useDashboard from './dashboard-hooks';
import NotorizationDoneImage from '../../components/Icons/NotorizationDoneImage';
import CardInfoLine from '../Checkout/Payment/CardInfoLine';
import Loader from '../../components/Loader';
import { apiUrls } from '../../variables/urls';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Layout from '../../components/Layout';
import Divider from '@mui/material/Divider';

const Dashboard = () => {
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );
  const {
    state,
    planDocuments,
    paymentDetails,
    user,
    onViewPDF,
    onRedirectToButtonClick,
    loading,
    showPaymentModal,
    proceedToPayment,
    companyID,
  } = useDashboard();
  const { btnText, text, status } = state;

  const Container = desktopView ? Paper : React.Fragment;

  if (status === 0 && !showPaymentModal) {
    return <Loader open={true} />;
  }

  return (
    <Layout isDocumentPage={false}>
      <Box
        p={2}
        sx={{
          mx: 6,
          mt: 4,
        }}
      >
        <Typography variant="m">{`Hi, ${
          planDocuments?.personal_information?.self?.first_name ??
          user?.email ??
          ''
        }`}</Typography>
        <Grid container xs={12} py={2} spacing={{ xs: 0, lg: 4 }}>
          <Grid xs={12} md={8} item>
            <React.Fragment>
              {status !== 3 && (
                <Container
                  sx={{
                    p: 3,
                  }}
                  elevation={4}
                >
                  <Typography
                    variant="lDisplay"
                    component="h2"
                    sx={{
                      display: { xs: 'none', lg: 'block' },
                    }}
                  >
                    Progress Check-in
                  </Typography>
                  <StepperContainer activeStep={status} />
                  <Typography variant="s" component="p" mb={3} align="center">
                    {text}
                  </Typography>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onRedirectToButtonClick(status)}
                    >
                      {btnText}
                    </Button>
                  </Box>
                </Container>
              )}
              {status === 3 && (
                <Box
                  component={NotorizationDoneImage}
                  sx={{
                    maxWidth: '100%',
                  }}
                />
              )}
              {(status === 3 || status === 2) && (
                <Container
                  sx={{
                    p: 3,
                    mt: 4,
                  }}
                  elevation={4}
                >
                  <Typography
                    variant="lDisplay"
                    component="h2"
                    sx={{
                      textAlign: { xs: 'center', lg: 'left' },
                      mt: { xs: 4, lg: 0 },
                    }}
                  >
                    My Documents
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    mt={4}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation={false}
                      sx={{
                        my: 3,
                      }}
                      startIcon={<DescriptionOutlined />}
                      onClick={onViewPDF}
                    >
                      View My Estate Plan
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation={false}
                      startIcon={<FileDownloadOutlined />}
                      onClick={onViewPDF}
                    >
                      Download My Estate Plan as PDF
                    </Button>
                  </Box>
                </Container>
              )}
            </React.Fragment>
          </Grid>
          <Grid
            xs={12}
            md={4}
            item
            sx={{
              display: { xs: 'none', lg: 'block' },
            }}
          >
            <Container
              sx={{
                p: 3,
              }}
              elevation={4}
            >
              <Typography variant="lDisplay" component="h2">
                My Account
              </Typography>
              <Box
                my={4}
                display="grid"
                gridTemplateColumns="1fr 70px"
                alignItems="flex-start"
              >
                <Box>
                  <Typography variant="m" component="h4">
                    Contact Information
                  </Typography>
                  <Typography variant="s" component="p" mt={1}>
                    {`${
                      planDocuments?.personal_information?.self?.first_name ??
                      ''
                    } ${
                      planDocuments?.personal_information?.self?.middle_name ??
                      ''
                    } ${
                      planDocuments?.personal_information?.self?.last_name ?? ''
                    }`}
                  </Typography>
                  <Typography variant="s" component="p">
                    {planDocuments?.personal_information?.self?.email ??
                      user?.email ??
                      ''}
                  </Typography>
                </Box>
                <MUILink
                  component={Link}
                  to={apiUrls.PERSONAL_INFORMATION}
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  Edit
                </MUILink>
              </Box>
              {!companyID && (
                <>
                  <Divider />
                  <Box
                    mt={4}
                    display="grid"
                    gridTemplateColumns="1fr 70px"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Typography variant="m" component="h4">
                        Payment Information
                      </Typography>
                      <CardInfoLine
                        cardType={paymentDetails?.paymentDetails?.brand ?? ''}
                        cardNumber={paymentDetails?.paymentDetails?.last4 ?? ''}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Loader open={loading} />
      <Dialog
        open={showPaymentModal}
        aria-labelledby="payment-confirm-dialog"
        aria-describedby="payment-confirm-dialog"
      >
        <DialogTitle id="payment-dialog-title">Payment failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your payment was not successful. Please try again.
          </DialogContentText>
        </DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={proceedToPayment}
              sx={{
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  ml: '0 !important',
                  width: '16rem',
                  flexDirection: 'column',
                },
              }}
            >
              Continue to Payment
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Layout>
  );
};
export default React.memo(Dashboard);
