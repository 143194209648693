import React from 'react';
import { Box, Breakpoint, Button, Grid } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import { Link as RouterLink } from 'react-router-dom';
import { messages } from './constants';
import AttorneyGrid from './AttorneyGrid';

const HomePageIntroduction = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item>
        <Box
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
            ml: -20.5,
          }}
        >
          <img
            src="images/home-graphic.png"
            height="931px"
            width="906px"
            alt="logo"
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm
        container
        direction="column"
        sx={{
          mt: 15,
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            textAlign: 'center',
            alignItems: 'center',
            mt: 10,
          },
        }}
      >
        <Grid
          item
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
          }}
        >
          <img src="images/TruWillLogo.png" width="180px" alt="logo" />
        </Grid>
        <Grid
          item
          sx={{
            mt: 2,
          }}
        >
          <Typography
            variant="xlDisplay"
            component="p"
            sx={{
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                maxWidth: '463px',
              },
            }}
          >
            Yes, Everyone Should Have an Estate Plan.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            mt: 2,
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                display: 'none',
              },
              ml: -5.5,
            }}
          >
            <img
              src="images/home-graphic.png"
              height="413px"
              width="400px"
              alt="logo"
            />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            mt: 1,
          }}
        >
          <Typography
            variant="mLight"
            component="p"
            sx={{
              maxWidth: '473px',
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                maxWidth: '320px',
              },
            }}
          >
            It’s how you make sure your loved ones and all of your assets are
            taken care of if anything were to happen to you.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            // to="/basic-info"
            // component={RouterLink}
            sx={{
              width: '252px',
              mt: 4,
            }}
          >
            {messages.getStarted}
          </Button>
        </Grid>
        <Grid
          item
          sx={{
            mt: 4,
          }}
        >
          <AttorneyGrid />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePageIntroduction);
